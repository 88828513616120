
import { Audits } from "@/api";
import { Audit, AuditsWithPagination } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { TableOptions } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { PageTitle }
})
export default class AuditsListPage extends Vue {
  headersTitle = [{ icon: "mdi-calendar-month", header: "Training Audits" }];
  nameFilter = "";
  page = 1;
  limit = 10;
  options: TableOptions = {} as TableOptions;
  audits: Array<Audit> = [];
  auditsWithPagination: AuditsWithPagination = {
    meta: {
      perPage: 10
    }
  };
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Duration",
      value: "duration"
    },
    {
      text: "Price",
      value: "price"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "150px",
      align: "center"
    }
  ];
  timeout = 0;
  @Watch("options", { deep: true })
  async onOptionsChange(val: any, old: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    if (old !== val) await this.loadAudits(val.page);
  }
  @Watch("nameFilter")
  async onFilterNameChange(val: any) {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      if (val.length >= 3) {
        await this.loadAudits(this.page, val);
      } else {
        await this.loadAudits(this.page);
      }
      this.options.page = 1;
    }, 500);
  }
  async loadAudits(page: number, name = "") {
    try {
      const auditsWithPagination = await Audits.list(
        page,
        this.nameFilter,
        this.limit
      );
      this.audits = auditsWithPagination.data ? auditsWithPagination.data : [];
      this.auditsWithPagination = auditsWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error([err.result.message] || err.errors);
    }
  }
  async onCreate() {
    await this.$router.push({ name: "CreateAudit" });
  }
  async onEdit(id: number) {
    await this.$router.push({
      name: "EditAudit",
      params: { id: id.toString() }
    });
  }
  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you realy want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Audits.remove(id.toString());
        await this.loadAudits(this.page);
        this.$success("<strong>Success!</strong> Audit has been deleted!");
      } catch (e) {
        const err = e as any;
        if (err.result.status === 400) {
          this.$error({ error: `Can't be deleted, ${err.result.message}` });
        } else await this.$error(err.errors);
      }
    }
  }
  moneyFormat(value: number | string) {
    const num = parseFloat(value.toString());
    const formattedMoney = new Intl.NumberFormat("en", {
      style: "currency",
      currency: "ZAR",
      minimumFractionDigits: 2
    }).format(num);
    return formattedMoney.substr(2);
  }
}
